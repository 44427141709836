

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;
    content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="NotoSerifKR"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="NotoSerifKR"]::before
{
    font-family: "NotoSerifKR", cursive;
    content: "NotoSerifKR" !important;
}






/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-NotoSerifKR {
  font-family: "NotoSerifKR";
}



.ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8";
  font-size: 8px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}


.ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30";
  font-size: 30px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36";
  font-size: 36px !important;
}

.ql-video {
  width:100%;
  aspect-ratio: 16/9;
}